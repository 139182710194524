.inviteLink {
    margin-top: 1rem;
    display: flex;
    background-color: #143D4A;
    max-width: 100%;
    justify-content: space-between;
    max-width: 15rem;
    border: none;
    color: white;
    padding: 0.5rem;
}

.inviteLink:hover {
    cursor: pointer;
}

.inviteLink > i {
    font-size: large;
}

.btnSubmit {
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
}