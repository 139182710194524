@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");
@import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
/* @import "~bootstrap/scss/bootstrap"; */

body {
  font-family: "Open Sans", sans-serif;
}

.signin {
  background-color: #0f3d49;
  width: 100%;
  height: 100vh;
  overflow: hidden !important;
  display: flex;
  align-items: center;
}

.signin-logo {
  position: relative;
  display: flex;
  justify-content: center;
  background-image: url(../assets/images/signin-bg.png);
  background-position: right;
  align-items: center;
  height: 100%;
  width: 40%;
  flex: 0 0 40%;
}

.signin-logo .bg-img {
  height: 100%;
}

.superAdmin-heading {
  font-size: 45px;
  font-weight: bold;
}

.login-form {
  width: 100%;
  padding: 50px 100px;
}

.login-form form {
  margin-top: 65px;
  width: 450px;
  margin: 65px auto 0;
}

.login-form form .form-group {
  margin-bottom: 24px;
}

.login-form form .form-group .form-control {
  height: 60px;
}

.btn {
  padding: 10px 40px;
  line-height: 1.5;
}

.btn-secondary {
  color: #ffffff !important;
  font-size: 18px;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

header {
  width: 100%;
  background-color: #ffffff;
  padding: 10px 40px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 20px;
  text-align: right;
  display: flex;
  justify-content: end;
  align-items: center;
  color: #0f3d49;
}

.super-admin-profile {
  display: flex;
  align-items: center;
}

.super-admin-profile img {
  width: 50px;
  height: 50px;
}

aside {
  background-color: #0f3d49;
  width: 300px;
  height: 100vh;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
}

aside > ul {
  margin-top: 50px;
  padding-left: 0;
  list-style: none;
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  scrollbar-width: none;
}

aside > ul::-webkit-scrollbar {
  width: 0;
}

aside > ul li {
  margin-bottom: 24px;
}

aside > ul li a {
  padding: 15px 30px;
  font-size: 18px;
  color: #ffffff;
  text-decoration: none;
  display: flex;
  align-items: center;
}

aside > ul li a:hover {
  color: #ffffff;
}

aside > ul li.active > a {
  background-color: rgba(255, 255, 255, 0.6);
  color: #0f3d49;
  font-weight: bold;
}

.sub-drp {
  background-color: #0f3d49;
  padding-left: 0;
  list-style: none;
}

.sub-drp li {
  margin-bottom: 0;
}

.sub-drp li a {
  font-size: 15px;
  padding: 0;
  padding-left: 65px;
  margin-bottom: 15px;
}

.sub-drp li a:first-child {
  margin-top: 15px;
}

.sub-drp li a i {
  margin-right: 10px;
}

.logo {
  text-align: center;
  padding-top: 20px;
}

main {
  margin-left: 300px;
  padding: 50px 40px;
}

.total-block {
  background-color: #ffffff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 15px 20px;
}

.total-block h2 {
  font-size: 50px;
  color: #0f3d49;
  font-weight: bold;
  margin-bottom: 0;
}

.total-block h5 {
  color: #009ade;
}

.page-heading {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 30px;
  color: #0f3d49;
}

.search {
  position: relative;
}

.search i {
  position: absolute;
  top: 50%;
  left: 15px;
  font-size: 24px;
  transform: translateY(-50%);
}

.search input {
  height: 50px;
  padding-left: 50px;
}

.table-card {
  margin-top: 40px;
  background-color: #ffffff;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 30px;
}

.main-table thead {
  border-bottom: 0;
}

.main-table thead th {
  border-bottom: 0;
  border-color: transparent;
  font-weight: 600;
  text-transform: uppercase;
  color: #0f3d49;
  padding: 24px 16px;
  white-space: nowrap;
}

.main-table tbody {
  border-top-width: 0px !important;
}

.main-table tbody td {
  border-top: 0;
  color: #707070;
  padding: 24px 16px;
  white-space: nowrap;
}

.main-table tbody td i {
  font-size: 24px;
  line-height: 100%;
}

a,
a:hover {
  text-decoration: none !important;
}

fieldset {
  border: 1px solid #ced4da;
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 24px;
}

fieldset .row:last-child .mb-4 {
  margin-bottom: 0 !important;
}

legend {
  float: none;
  width: auto;
  padding: 0 10px;
  font-size: 18px;
  margin-bottom: -10px;
}

.DatePicker {
  display: block;
  position: relative;
}

.DatePicker::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zM4 9v10h16V9H4zm2 2h2v2H6v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2z' fill='rgba(15,61,73,1)'/%3E%3C/svg%3E");
  width: 24px;
  height: 24px;
  background-size: cover;
}

.DatePicker .DatePicker__input {
  text-align: left;
  padding-right: 44px;
}

.del-dialogue.success {
  color: #0f3d49;
}

.del-dialogue.success h5 {
  line-height: 30px;
}

.del-dialogue .modal-sm {
  max-width: 350px;
}

.del-dialogue .modal-body {
  padding: 30px 20px;
}

.del-dialogue .modal-body h6 {
  line-height: 30px;
}

.del-dialogue .modal-content {
  border-radius: 8px;
  overflow: hidden;
}

.del-dialogue .modal-footer {
  display: flex;
  flex-wrap: nowrap;
}

.del-dialogue .modal-footer .btn {
  width: 50%;
  border-radius: 0;
  height: 50px;
}

.del-dialogue .modal-footer > * {
  margin: 0;
}

.btn-gray {
  background-color: #b1b1b1;
  color: #ffffff;
  border-color: #b1b1b1;
}

.btn-gray:hover {
  background-color: #707070;
  border-color: #707070;
}

::-moz-selection {
  background-color: rgba(0, 154, 222, 0.8);
  color: #ffffff;
}

::selection {
  background-color: rgba(0, 154, 222, 0.8);
  color: #ffffff;
}

.btn-status {
  padding: 5px 15px;
  border-radius: 50px;
  cursor: pointer;
  border: 0;
}

.super-admin-profile-menu .dropdown-toggle::after,
.notification-menu .dropdown-toggle::after {
  display: none !important;
}

.super-admin-profile-menu button,
.notification-menu button {
  padding: 0;
  background-color: #ffffff !important;
  color: #0f3d49 !important;
  border: 0;
}

.super-admin-profile-menu button:focus,
.notification-menu button:focus {
  box-shadow: none !important;
}

.notification-menu .notification {
  width: 400px;
}

.notification-menu .notification-header {
  font-size: 20px;
  font-weight: 600;
  color: #0f3d49;
  border-bottom: 1px solid #eee;
}

.notification-menu .notification-body {
  max-height: 400px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #0f3d49;
}

.notification-menu .notification-body::-webkit-scrollbar {
  width: 5px;
}

.notification-menu .notification-body::-webkit-scrollbar-thumb {
  background-color: #0f3d49;
}

.notification-menu .notification-body .dropdown-item {
  padding: 15px 15px;
  display: flex;
}

.notification-menu .notification-body .dropdown-item .noti-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
}

.notification-menu .notification-body .dropdown-item .noti-content {
  width: 100%;
}

.notification-menu .notification-body .dropdown-item .noti-content p {
  font-size: 12px;
  margin-bottom: 0;
  color: #999;
  white-space: normal;
}

.notification-menu .notification-footer {
  border-top: 1px solid #eee;
}

.notification-menu .notification-header,
.notification-menu .notification-footer {
  padding: 10px 20px;
}

/*# sourceMappingURL=style.css.map */

.errorBorder {
  border: 2px solid red !important;
}

.k-button-flat-primary {
  color: #212529 !important;
}

.text-end {
  text-align: right !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #1e2125 !important;
  background-color: #e9ecef !important;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.formSelect {
  display: block;
  width: 100%;
  height: 2.875rem;
  padding: 0.1rem 3.375rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 2px;
}

.add-more_Address {
  margin-top: 15px;
  margin-left: auto;
  display: block;
}

.form-control-icon {
  position: absolute;
  top: 17px;
  right: 39px;
}
.Toastify__toast {
  width: 400px !important;
}
