.header {
    text-align: center;
    font: bold;
}

.listGroup {
    border: 1px solid gray;
    max-width: 15rem;
    height: 2rem;
    opacity: 0.2;
}

.container {
    padding: 0 4rem;
    margin-top: 2rem;
}